import axios from '@axios'
import { $api } from '@apiConfig'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchOrdersCount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.order}/count`, {
            params: queryParams,
            paramsSerializer: params => qs.stringify(params, { encode: false }),
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrders(ctx, queryParams) {
      const query = qs.stringify(queryParams, { encode: false })
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.order}?${query}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOrder(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${$api.app.order}`, order)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrder(ctx, { id, order }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${$api.app.order}/${id}`, order)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    signOrder(ctx, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${$api.app.order}/${id}/sign`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOrder(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${$api.app.order}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    upsertOrder(ctx, order) {
      const { id } = order
      if (id) {
        return new Promise((resolve, reject) => {
          axios
            .put(`${$api.app.order}/${id}`, order)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${$api.app.order}`, order)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.order}/${id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.user}`, {
            params: queryParams,
            paramsSerializer: params => qs.stringify(params, { encode: false }),
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSalespersons() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.user}?role=salesperson`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgreementLinks() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.object}?type=termsandconditions`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addClient(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post($api.app.user, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post($api.app.product, productData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
