import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useOrderHandler from '../useOrderHandler'

export default function useOrdersList() {
  const { decoratePaymentDetails } = useOrderHandler()
  // Use toast
  const toast = useToast()

  const refOrderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'client', sortable: true },
    { key: 'status', sortable: true },
    { key: 'type', sortable: true },
    { key: 'total', sortable: true },
    { key: 'issuedDate', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('issuedDate')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refOrderListTable.value ? refOrderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    }
  })

  const refetchData = () => {
    refOrderListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchOrders = (ctx, callback) => {
    const payload = {
      _where: {
        _or: [],
      },
    }
    switch (router.currentRoute.name) {
      case 'apps-users-view':
        // eslint-disable-next-line no-underscore-dangle
        payload._where._or.push({ 'client.id': router.currentRoute.params.id })
        // eslint-disable-next-line no-underscore-dangle
        payload._where._or.push({ 'salesperson.id': router.currentRoute.params.id })
        break
      default:
        break
    }
    if (statusFilter.value) {
      // eslint-disable-next-line no-underscore-dangle
      payload._where.status = statusFilter.value
    }
    if (searchQuery.value) {
      const fullTextSearchFields = ['client.firstName', 'client.lastName', 'client.fullName', 'client.phoneNumber', 'client.email', 'referenceNumber', 'externalId']
      fullTextSearchFields.forEach(field => {
        const obj = {}
        obj[`${field}_contains`] = searchQuery.value
        // eslint-disable-next-line no-underscore-dangle
        payload._where._or.push(obj)
      })
    }
    store
      .dispatch('app-order/fetchOrders',
        {
          ...payload,
          _limit: perPage.value,
          _start: (currentPage.value - 1) * perPage.value,
          _sort: `${sortBy.value}:${isSortDirDesc.value ? 'DESC' : 'ASC'}`,
        })
      .then(response => {
        const orders = response.data
        const ordersFiltered = orders.filter(order => order.client)
        ordersFiltered.forEach(order => {
          /* eslint-disable no-param-reassign */
          try {
            order.paymentDetails = decoratePaymentDetails(order.paymentDetails)
          } catch (e) {
            console.log(`Unexpected error decorating paymentDetails ${JSON.stringify(e.message)}`)
          }
          /* eslint-enable no-param-reassign */
        })
        callback(ordersFiltered)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching orders' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    store
      .dispatch('app-order/fetchOrdersCount', payload)
      .then(response => {
        const ordersLength = response.data
        totalOrders.value = ordersLength
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching total count',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOrderStatusVariantAndIcon = status => {
    if (status === 'pending') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'completed') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'contracted') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'created') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'quoted') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'cancelled') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientRoleVariant = role => {
    if (role === 'salesperson') return 'primary'
    if (role === 'client') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }
  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }

  const deleteOrder = orderId => {
    // TODO: add a warning dialog to confirm
    store.dispatch('app-order/deleteOrder', orderId)
      .then(response => {
        const { status } = response
        toast({
          component: ToastificationContent,
          props: {
            title: `Successfully deleted transaction ${orderId}`,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData()
        return status
      })
      .catch(exception => {
        console.log(`Error ${JSON.stringify(exception)}`)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting transaction',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return 500
      })
  }

  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrderListTable,

    statusFilter,
    resolveClientRoleVariant,
    resolveOrderStatusVariantAndIcon,
    resolveClientAvatarVariant,
    deleteOrder,
    refetchData,
  }
}
