<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'apps-order-add'}"
          >
            Add Record
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="order-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refOrderListTable"
      :items="fetchOrders"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-order-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.item.referenceNumber }}
        </b-link>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :variant="`light-${resolveClientRoleVariant(data.item.role)}`"
              :text="avatarText(`${data.item.client.firstName} ${data.item.client.lastName}`)"
              :to="{ name: 'apps-users-view', params: { id: data.item.client.id } }"
            />
          </template>
          <b-link
            :to="{ name: 'apps-users-view', params: { id: data.item.client.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ `${data.item.client.firstName} ${data.item.client.lastName}` }}
          </b-link>
          <small class="text-muted">{{ data.item.client.email }}</small>
        </b-media>
      </template>

      <!-- Column: Total -->
      <template #cell(total)="data">
        <template>
          <span class="text-nowrap">
            ${{ data.item.paymentDetails.total }}
          </span>
        </template>
      </template>

      <!-- Column: Type -->
      <template #cell(type)="data">
        <template>
          <span class="text-nowrap text-capitalize">
            {{ data.item.type }}
          </span>
        </template>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span
          class="text-nowrap"
        >
          {{ data.item.issuedDate| formatDate }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <template>
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveOrderStatusVariantAndIcon(data.item.status)"
              size="18"
              class="mr-50"
              :class="`text-${resolveOrderStatusVariantAndIcon(data.item.status)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.status }}</span>
          </div>
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <a
            target="_blank"
            :href="`/apps/order/preview/${data.item.id}?showSignatureControl=true&token=token`"
          >
            <feather-icon
              :id="`order-row-${data.item.id}-send-icon`"
              icon="SendIcon"
              class="cursor-pointer"
              size="16"
            />
          </a>
          <b-tooltip
            title="Send Order"
            class="cursor-pointer"
            :target="`order-row-${data.item.id}-send-icon`"
          />

          <a
            @click="$router.push({ name: 'apps-order-preview', params: { id: data.item.id }})"
          >
            <feather-icon
              :id="`order-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1"
            />
          </a>
          <b-tooltip
            title="Preview Order"
            :target="`order-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-order-preview', params: { id: data.item.id } }">
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-order-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalOrders"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useOrdersList from './useOrderList'
import OrderStoreModule from '../OrderStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  methods: {
    confirmDelete(orderId) {
      this.$swal({
        title: 'Are you sure to delete transaction?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteOrder(orderId).then(statusCode => {
            if (statusCode === 200) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Transaction has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else {
              this.$swal({
                icon: 'failed',
                title: 'Error!',
                text: 'Transaction has not been deleted.',
                customClass: {
                  confirmButton: 'btn btn-outline-danger ml-1',
                },
              })
            }
          })
        }
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, OrderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'created',
      'quoted',
      'confirmed',
      'cancelled',
      'refunded',
      'pending',
      'completed',
    ]

    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrderListTable,

      statusFilter,
      deleteOrder,
      refetchData,
      resolveClientRoleVariant,
      resolveOrderStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useOrdersList()

    return {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      resolveClientRoleVariant,
      statusFilter,
      deleteOrder,
      refetchData,

      statusOptions,

      avatarText,
      resolveOrderStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.order-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
