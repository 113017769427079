const { Validator } = require('jsonschema')

const ORDER_SCHEMA = {
  $id: 'https://vuexycrm.com/order.schema.json',
  $schema: 'https://json-schema.org/draft/2020-12/schema',
  title: 'order',
  type: 'object',
  properties: {
    source: {
      type: 'string',
    },
    salesperson: {
      type: 'object',
    },
    serviceDate: {
      type: 'datetime',
    },
    notes: {
      type: 'string',
    },
    paymentDetails: {
      type: 'object',
      properties: {
        tax: {
          type: 'number',
          minimum: 0,
        },
        deposit: {
          type: 'number',
          minimum: 0,
        },
        less: {
          type: 'number',
          minimum: 0,
        },
        paymentMethod: {
          type: 'string',
        },
        subtotal: {
          type: 'number',
          minimum: 0,
        },
      },
    },
    signedDate: {
      type: 'datetime',
    },
    signedBy: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    client: {
      type: 'object',
    },
    items: {
      type: 'array',
    },
  },
  required: ['items', 'client', 'salesperson', 'paymentDetails'],
  additionalProperties: true,
}

export default function useOrderHandler() {
  /* eslint-disable no-param-reassign */
  const decoratePaymentDetails = paymentDetails => {
    const fields = ['deposit', 'taxRate', 'subtotal', 'total', 'balance', 'tax']
    if (!paymentDetails) {
      paymentDetails = {
        deposit: 0,
        taxRate: 0.13, // Ontario default taxRate as of 2021
        subtotal: 0,
        total: 0,
        balance: 0,
        tax: 0,
      }
    }

    try {
      paymentDetails.tax = paymentDetails.subtotal * paymentDetails.taxRate
      paymentDetails.total = paymentDetails.subtotal + paymentDetails.tax
      paymentDetails.balance = paymentDetails.total - (paymentDetails.deposit || 0) - paymentDetails.less
      // eslint-disable-next-line no-empty
      // eslint-disable-next-line no-return-assign
      fields.forEach(field => paymentDetails[field] = parseFloat(paymentDetails[field].toFixed(2)))
    } catch (e) {
      console.log(e)
      return paymentDetails
    }
    return paymentDetails
  }
  /* eslint-enable no-param-reassign */
  const validateOrder = orderData => {
    try {
      // eslint-disable-next-line no-param-reassign
      if (orderData.items && orderData.items.length === 0) delete orderData.items
      const v = new Validator()
      return v.validate(orderData, ORDER_SCHEMA)
    } catch (e) {
      return {
        valid: false,
        errors: [e],
      }
    }
  }

  return {
    decoratePaymentDetails,
    validateOrder,
  }
}
